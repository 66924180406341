import React from 'react';
import Footer from './Footer';
import HeaderMenu from './HeaderMenu';

const Layout = ({children}) => {

    return (
        <>
            <div className='main-section'>
                <HeaderMenu/>
                {children}
                <Footer/>
            </div>
        </>
    );
};

export default Layout;