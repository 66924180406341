import { useField } from "formik";
import * as PR from "../prime-modules/index";
import './input.scss'
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { useSelector } from "react-redux";

const Input = (props) => {

    const [field, meta] = useField(props);
    const [, , helpers] = useField('phone');
    const {validations} = useSelector(state=>state.language?.activelang);

    const { setValue } = helpers;
    const phoneCh = (v, data) => { setValue(data?.dialCode + '-' + v.slice(data?.dialCode.length, v.length)); }
    const { placeholder, ...rest } = props;
    const placeholderValue = field?.value?.length === 0 ? placeholder : "";
    
    return <div className="flex flex-column gap-2 input-field">
        <label htmlFor={props?.name}> {props?.label}</label>
        {
            props?.name === 'phone'
                ? <div className="custom-phoneno-field"><PhoneInput countryCodeEditable={false} autoFormat={true} value={field.value} onChange={phoneCh} enableSearch inputProps={{ ...props, onBlur: field.onBlur, className: 'p-inputtext' }} /></div>
                : props?.type === 'password'
                    ? <PR.Password  {...field} {...props} autoComplete="new-password" />
                    : props?.type === "message" ? <PR.InputTextarea  {...field} {...props} /> 
                    : props?.type === "websites" ? <PR.Chips placeholder={placeholderValue} {...field} {...rest} separator=","  addOnBlur={true}
                    allowDuplicate={false}/> 
                    : <PR.InputText {...field} {...props} autoComplete="username" />
        }
       {meta.touched && meta.error && <span className="err-msg">{validations[meta.error]??'Error'}</span>}
    </div>
}


export default Input