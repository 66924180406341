import { useSelector } from "react-redux";
import { globalConfig } from "../constants";

const SupportMailsSection = () => {
  const {labels} = useSelector(state=>state.language?.activelang);

  return (
    <section className="about-blocks-section tc-block">
      <div className="terms-and-conditions-section grid">
        <div className="col-12 lg:col-4 md:col-4">
          <h3>{labels?.LBLN0218}</h3>
          <p>
            {labels?.LBLN0244} <br />
            {labels?.LBLN0245}
          </p>
          <a
            href={`mailto:${globalConfig.supportEmail}`}
            className="email-link"
          >
            {globalConfig.supportEmail}
          </a>
        </div>
        <div className="col-12 lg:col-4 md:col-4">
          <h3>{labels?.LBLN0214}</h3>
          <p>
          {labels?.LBLN0244} <br />
            {labels?.LBLN0245}
          </p>
          <a
            href={`mailto:${globalConfig.salesEmail}`}
            className="email-link"
          >
            {globalConfig.salesEmail}
          </a>
        </div>
        <div className="col-12 lg:col-4 md:col-4">
          <h3>{labels?.LBLN0246}</h3>
          <p>
            {labels?.LBLN0250}-{labels?.LBLN0248}{" "}
            {labels?.LBLN0247} 8am {labels?.LBLN0249}{" "}
            <br />
            5pm.
          </p>
            <a
            href={`tel:${globalConfig.phoneNumber}`}
            className="email-link"
          >
            {globalConfig.phoneNumber}
          </a>
        </div>
      </div>
    </section>
  );
};

export default SupportMailsSection;
