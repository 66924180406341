export const globalConfig = {
    domainName: 'data2go',
    dataSize: 'GB',
    libraries: ['places'],
    allowPhoneNumberLength: 16,
    defaultCurrency: 'usd',
    paymentDefaultCurrency: 'usd',
    paymentDefaultCountry: 'US',
    paymentDefaultLabel: 'eSIM Purchase',
    supportEmail: 'support@data2go.mobi',
    contactEmail: 'contact@data2go.mobi',
    salesEmail: 'sales@data2go.mobi',
    phoneNumber:"+31 651683106",
    callUsPhoneNumber:"+44 1223 926858",
    allowNameLength: 20,
    toastDisplayTime: 10000,
    deviceInfo: "website",
    isEsimCompatible: false,
    catalogDataAmountperGB: 1000,
    notificationCount:20,
    languageObjectLength: 3,
}

export const envConfig = process.env

export const responsiveOptions = [
    {
        breakpoint: "1199px",
        numVisible: 3,
        numScroll: 1
    },
    {
        breakpoint: "991px",
        numVisible: 2,
        numScroll: 1
    },
    {
        breakpoint: "767px",
        numVisible: 1,
        numScroll: 1
    }
];
