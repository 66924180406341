export const envConfig = process.env;
export const globalConfig = {
    domainName: 'data2go',
    dataSize: 'GB',
    defaultCurrency: '$',
    libraries: ['places'],
    allowPhoneNumberLength: 16,
    paymentDefaultCurrency: 'usd',
    paymentDefaultCountry: 'US',
    paymentDefaultLabel: 'eSIM Purchase',
    pageCount: 20,
    domainUrl:"https://data2go.mobi",
    supportEmail: 'support@data2go.mobi',
    contactEmail: 'contact@data2go.mobi',
    salesEmail: 'sales@data2go.mobi',
    phoneNumber:"+44 1223 926858",
    allowNameLength: 20,
    toastDisplayTime: 10000,
    deviceInfo: "website",
    isEsimCompatible: false,
    catalogDataAmountperGB: 1000,
    notificationCount:20
}