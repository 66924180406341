import React, {useRef, useState} from "react";
import * as PR from "../../prime-modules/index";
import { Link, useNavigate } from 'react-router-dom';
import Input from "../../components/input";
import { Form, Formik } from "formik";
import "./index.scss";
import Layout from "../../components/layout/Layout";
import { signUpAPI } from "../../services/affiliateApi";
import { useMutation } from "react-query";
import { signUpValidationSchema } from "../../utils/schema";
import { useSelector } from "react-redux";

const AffiliateSignup = () => {
    const { affiliate, labels } = useSelector(state => state.language?.activelang);
    const toast = useRef();
    const formikRef = useRef(null);
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '+91',
        password: '',
        afid:'',
        confirmPassword: '',
        websites: []
    }
    
    const {mutate, isLoading} = useMutation(signUpAPI,{
        onSuccess:(o)=>{
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Registered successfully.' });
            formikRef.current.resetForm();
            setTimeout(() => {
                navigate('/affiliate-signin');
              }, 2000);
        },
        onError:(o)=>{
            toast.current.show({ severity: 'error', summary: 'Error', detail: o?.message})
        }
    })

    const submitHanlder  = value => {
        const [callingCode, localPhoneNumber] = `+${value?.phone}`?.split('-');
        mutate({ ...value, phone: { callingCode, localPhoneNumber }});
    }

    return (
    <>  
        <Layout>
            <PR.Toast ref={toast} />
            <section className="signup-section">
            <div className="grid">
                <div className="col-12 text-center">
                    <h1>{affiliate?.AFFN0042}</h1>
                    <p>{labels?.LBLN0233}<br/>{labels?.LBLN0308}</p>
                    <div className="signup-wrapper">
                        {/* Form section starts here */}
                        <Formik innerRef={formikRef} initialValues={initialValues} validationSchema={signUpValidationSchema} onSubmit={submitHanlder} >
                            <Form autoComplete="off" className="form-block" >
                                <div className='grid'>
                                    <div className="col-12 md:col-6">
                                        <Input id={'firstName'} name={'firstName'} label={labels?.LBLN0001} type={'text'} aria-describedby={'f-name'} placeholder={labels?.LBLN0207} />
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <Input id={'lastName'} name={'lastName'} label={labels?.LBLN0003}  type={'text'} aria-describedby={'l-name'} placeholder={labels?.LBLN0208} />
                                    </div>
                                    <div className="col-12 md:col-12">
                                        <Input id={'email'} name={'email'} label={labels?.LBLN0037} type={'email'} aria-describedby={'email'} placeholder={labels?.LBLN0209} />
                                    </div>
                                    <div className="col-12 md:col-12">
                                        <Input id={'phone'} name={'phone'} label={labels?.LBLN0011} type={'text'} aria-describedby={'email'} placeholder={labels?.LBLN0213} />    
                                    </div>
                                    <div className="col-12 md:col-12 relative">
                                        <Input id={'afid'} name={'afid'} label={labels?.LBLN0304} type={'text'} aria-describedby={'af-id'} placeholder={labels?.LBLN0305} /> 
                                        <div className="afid-span">
                                            <span className="custom-tooltip-btn pi pi-info-circle"></span>
                                            <PR.Tooltip target=".custom-tooltip-btn">
                                                <p>{labels?.LBLN0309}</p>
                                            </PR.Tooltip>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-12">
                                        <Input id={'websites'} name={'websites'} label={labels?.LBLN0306}  type={'websites'} aria-describedby={'website'}  placeholder={labels?.LBLN0307} />   
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <Input id={'password'} name={'password'} feedback={false} type={'password'}  label={labels?.LBLN0071} aria-describedby={'password'} toggleMask placeholder={labels?.LBLN0228} />    
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <Input id={'confirmPassword'} name={'confirmPassword'} feedback={false} type={'password'} label={labels?.LBLN0005} aria-describedby={'confirm-password'} toggleMask placeholder={labels?.LBLN0229} />    
                                    </div>

                                    <div className="flex align-items-top gap-3 mb-5">
                                        <PR.Checkbox inputId="enableButton" checked={isChecked} onChange={(e) => setIsChecked(e.checked)}></PR.Checkbox>
                                        <label htmlFor="enableButton">{labels?.LBLN0319} <Link target="_blank" to="/terms-and-conditions">{labels?.LBLN0320}</Link></label>
                                    </div>

                                    <div className="col-12">
                                        <PR.Button disabled={!isChecked} label={labels?.LBLN0226} type='submit' className="signup-button w-12"/>
                                    </div>
                                </div>
                                <p className="link-p">{labels?.LBLN0087} <Link to="/affiliate-signin">{labels?.LBLN0068}</Link></p>
                            </Form>
                        </Formik>
                        {/* Form section ends here */}
                    </div>
                </div>
            </div>
        </section>
        </Layout>
    </>
  );
};

export default AffiliateSignup;
