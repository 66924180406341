import * as PR from "../../prime-modules/index";
import Layout from "../../components/layout/Layout"
import Input from "../../components/input";
import { Form, Formik } from "formik";
import { otpValidateSchema } from "../../utils/schema";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { verifyEmail } from "../../services/affiliateApi";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";


const VerifyEmail = () => {
    const { labels } = useSelector(state => state.language?.activelang);
    const {search} = useLocation();
    const userId = Number(new URLSearchParams(search).get('id'))
    const toast = useRef(null);
    // const [timer,setTimer] = useState(30);
    const navigate = useNavigate();

    useEffect(()=>{
       if(!userId) navigate('/');
    },[userId,navigate]);

    // useEffect(()=>{
    //     const t = setInterval(() => {
    //         if(timer!=='') setTimer(prev=>prev-1);
    //     }, 1000);
    //     if(timer===0) {clearInterval(t); setTimer('')};
    //     return()=>clearInterval(t);
    // },[timer,setTimer]);

    const {mutate,isLoading} = useMutation(verifyEmail,{
        onSuccess: () =>{
            toast.current.show({ life: 10000, severity: 'success', summary: 'Success', detail: 'Your email address has been verified successfully.' });
            setTimeout(() => {
                navigate('/affiliate-signin');
              }, 2000);
            },
        onError : o => {toast.current.show({ severity: 'error', summary: 'Error', detail: o?.message }) }
    });
  
    const submitHanlder = ({otp:code}) => {
        mutate({userId,code});
    };
    

    return <Layout>
        <PR.Toast ref={toast} />
        <section className="signup-section">
            <div className="grid">
                <div className="col-12 text-center">
                    <h1>{labels?.LBLN0045}</h1>
                    <p>{labels?.LBLN0303}</p>
                    <div className="signup-wrapper">
                        {/* Form section starts here */}
                        <Formik initialValues={{otp: ''}} validationSchema={otpValidateSchema} onSubmit={submitHanlder} >
                            <Form autoComplete="off" className="form-block">
                                <div className='grid'>
                                    <div className="col-12 md:col-12">
                                        <Input 
                                            id={'otp'}
                                            name="otp"
                                            feedback={false}
                                            type={'password'} 
                                            aria-describedby={'otp'}
                                            toggleMask
                                            placeholder={labels?.LBLN0048}
                                        />
                                    </div>
                                    {/* <div className="col-4">
                                        <PR.Button disabled={isLoadingResend||!!timer} style={{fontSize:16}} onClick={mutateResendOTP.bind(null,{email,device:'string'})} label={`Resend OTP ${timer}`} type='button' className="button button-secondary w-12 mt-2"/>
                                    </div> */}
                                    <div className="col-12">
                                        <PR.Button disabled={isLoading} label={labels?.LBLN0235} type='submit' className="signup-button w-12"/>
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
}
export default VerifyEmail;