
import './i18n';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom"
import AffiliatesDashboard from './pages/dashboard';
import AffiliateSignin from './pages/signin';
import AffiliateSignup from './pages/signup';
import TermsAndCondition from './pages/help/TermsAndCondition';
import PrivacyPolicy from './pages/help/PrivacyPolicy';
import CookiePolicy from './pages/help/CookiePolicy';
import { Provider } from 'react-redux';
import store from './store';
import { QueryClient, QueryClientProvider } from 'react-query';

// **** Main CSS **** //
import './assets/scss/main.scss';
import VerifyEmail from './pages/signup/VerifyEmail';

function Navigation() {
  const { afIsAuthenticated } = useSelector(state => state.afAuth);
  return (
    <Router>
      <Routes>
        {/* Affiliate Public Route */}
        <Route element={afIsAuthenticated ? <Navigate to='/affiliates-dashboard' /> : <Outlet />} >
        <Route path='/' element={ <AffiliateSignin />} />
          <Route path='/affiliate-signup' element={ <AffiliateSignup />} />
          <Route path='/verify-email' element={<VerifyEmail />} />
          <Route path='/terms-and-conditions' element={ <TermsAndCondition />} />        
            <Route path='/privacy-policy' element={ <PrivacyPolicy />} />    
            <Route path='/cookie-policy' element={ <CookiePolicy />} />   
        </Route>

        {/* Affiliate Private Route */}
        <Route element={afIsAuthenticated ?  <Outlet /> : <Navigate to='/affiliate-signin' />} >
            <Route path='/affiliates-dashboard' element={ <AffiliatesDashboard />} />
        </Route>
        <Route path='*' element={<Navigate to='/' />} />
        
      </Routes>
    </Router>
  );
}

export default function App(){

  const queryClient = new QueryClient();

  return <Provider store={store} >
    <QueryClientProvider client={queryClient}>
      <Navigation/>
    </QueryClientProvider>
  </Provider>
};
