import moment from "moment"
import { globalConfig } from "./constant";
export const modifyDate = (orderDate) => {
    const year = new Date(orderDate).getFullYear();
    return year !== 1 ? moment(orderDate).format("DD-MM-YYYY HH:mm:ss") : "";
  };
  export const setPrice = (price) => {
    if(price == 0){
      return `${globalConfig.defaultCurrency + price}.00`
    }else if(typeof price === "number"){
      return price && globalConfig.defaultCurrency + setNumberPrecision(price);
    }else{
      return globalConfig.defaultCurrency + price
    }
    
  };
  
  export const setNumberPrecision = (val) => {
    return !!val && val?.toFixed(2);
  };
  const formatDecimals = (num) => {
    const calcDec = Math.pow(10, 1);
    return Math.trunc(num * calcDec) / calcDec;
  }

  export const formattedDate = (timestamp) => `${new Date(timestamp).toLocaleString('en-US', { 
      day: 'numeric', 
      month: 'short', 
      year: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      hour12: true 
  })}`;

  const checkFloatingValue = (formattedData) => {
    return Number.isInteger(formattedData) ? formattedData : formatDecimals(formattedData)
  }

  export const checkDataSize = (dataSizeInBytes) => {
    const bytesInOneMB = 1000 * 1000; // 1 MB = 1024 KB, 1 KB = 1024 bytes
    const bytesInOneGB = bytesInOneMB * 1000; // 1 GB = 1024 MB
    if (dataSizeInBytes >= bytesInOneGB) {
      // Data is in GB
      const dataSizeInGB = dataSizeInBytes / bytesInOneGB;
      return checkFloatingValue(dataSizeInGB) + " GB";
    } else if (dataSizeInBytes >= bytesInOneMB) {
      // Data is in MB
      const dataSizeInMB = dataSizeInBytes / bytesInOneMB;
      return checkFloatingValue(dataSizeInMB) + " MB";
    } else if(dataSizeInBytes >= 1000) {
      // Data is in bytes
      const dataSizeInMB = dataSizeInBytes / 1000;
      return checkFloatingValue(dataSizeInMB) + " KB";
    } else {
      return 0 + " KB"
    }
  };

  export const displayBase64 = (image)=>"data:image/png;base64,"+image