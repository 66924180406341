import React, { useRef } from "react";
import { Form, Formik } from "formik";
import * as PR from "../../prime-modules/index";
import { Link } from 'react-router-dom';
import Input from "../../components/input";
import { useMutation } from "react-query";
import { afLogin } from "../../services/affiliateApi";
import { siginValidationSchema } from "../../utils/schema";
import { useDispatch, useSelector } from "react-redux";
import { afLoginReducer } from "../../store/afAuth.slice";
import Layout from "../../components/layout/Layout";

const AffiliateSignin = () => {
  const { labels, affiliate } = useSelector(state => state.language?.activelang);
    const dispatch = useDispatch();
    const toast = useRef();
    const {mutate, isLoading} = useMutation(afLogin,{
      onSuccess:o=>{dispatch(afLoginReducer(o));},
      onError: o => {
        toast.current.show({ severity: 'error', summary: 'Error', detail:o.message })
      }
    });

    return (
      <>
      <Layout>
          <PR.Toast ref={toast} />
          <section className="signup-section">
            <div className="grid">
              <div className="col-12 text-center">
                <h1>{affiliate?.AFFN0043}</h1>
                <p>{labels?.LBLN0231}</p>
                <div className="signup-wrapper">
                  {/* Form section starts here */}
                  <Formik initialValues={{ email: "", password: "", }} onSubmit={mutate} validationSchema={siginValidationSchema} >
                    <Form autoComplete="off" className="form-block">
                      <div className="grid">
                        <div className="col-12 md:col-12">
                          <Input
                            label={labels?.LBLN0037}
                            name={"email"}
                            type={"email"}
                            aria-describedby={"email"}
                            placeholder={labels?.LBLN0209}
                          />
                        </div>
                        <div className="col-12 md:col-12">
                          <Input
                            feedback={false}
                            label={labels?.LBLN0071} 
                            aria-describedby={'password'} 
                            toggleMask 
                            placeholder={labels?.LBLN0228}
                            name={"password"}
                            type={"password"}
                          />
                        </div>
                        <div className="col-12 pb-0">
                          <PR.Button
                            label={labels?.LBLN0068}
                            type="submit"
                            className="signup-button w-12"
                            disabled={isLoading}
                            icon={isLoading&&"pi pi-spin pi-spinner"}
                          />
                          {/* <p className="text-right forgot-pass-p">
                            <Link to="/forgot-password">Forgot password?</Link>
                          </p> */}
                        </div>
                      </div>
                      {/* <GoogleAndAppleSingin/> */}
                      <p className="link-p">
                       {labels?.LBLN0070} <Link to="/affiliate-signup">{labels?.LBLN0226}</Link>
                      </p>
                    </Form>
                  </Formik>
                  {/* Form section ends here */}
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    );
};

export default AffiliateSignin;
