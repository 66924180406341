import { createSlice } from "@reduxjs/toolkit";
import { AF_USER_SESSION_DATA } from "../utils/localstore";



const afUserData = JSON.parse(localStorage.getItem(AF_USER_SESSION_DATA) ?? null);
const afIsAuthenticated = !!afUserData;

const initialState = { afIsAuthenticated, afUserData }

const afAuthSlice = createSlice({
    name:'af-auth',
    initialState,
    reducers:{
        afLoginReducer(state,{payload}){
            state.afIsAuthenticated = true;
            state.afUserData = payload;
            localStorage.setItem(AF_USER_SESSION_DATA,JSON.stringify(payload));
        },
        afLogoutReducer(state){
            state.afIsAuthenticated = false;
            state.afUserData = {};
            localStorage.removeItem(AF_USER_SESSION_DATA);
        },
        
    }
});

export const {afLoginReducer,afLogoutReducer} = afAuthSlice.actions

export default afAuthSlice.reducer;
