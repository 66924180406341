import * as Yup from "yup";
import { parsePhoneNumber } from 'react-phone-number-input';

Yup.addMethod(Yup.string, "phoneNumber", function (message) {
  return this.test("phoneNumber", message, function (value, data) {
    const { path, createError } = this;

    try {
      // Clean the phone number by removing any dashes
        let number ;
        if(value.includes("-")) number = `+${data.originalValue.replace("-","")}`
      // Parse the phone number
      const phoneNumber = parsePhoneNumber(number || value);
      // Check if the phone number is valid
      if (!phoneNumber || !phoneNumber.isValid()) {
        return createError({
          path,
          message: message || "Invalid phone number",
        });
      }

      return true;
    } catch (error) {
      return createError({ path, message: message || "Invalid phone number" });
    }
  });
});

const firstNameSchema =
      Yup.string()
        .trim()
        .required('VLDN0007')
        .matches(/^[A-Za-z]+$/, 'VLDN0018')
        .min(3, 'VLDN0008')
        .max(20, 'VLDN0008');

  const afidSchema =
    Yup.string()
      .trim()
      .matches(/^[0-9a-zA-Z_-]{8,36}$/, 'VLDN0032')
      .min(8, 'VLDN0032')
      .max(36, 'VLDN0032');

    const emailSchema =
      Yup.string()
        .trim()
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/,
          'VLDN0006'
        )
        .email('VLDN0006')
        .required('VLDN0005');

 const phoneSchema =
  Yup.string().required('VLDN0021').phoneNumber('VLDN0019');
    

    const passwordSchema =
      Yup.string()
        .trim()
        .required('VLDN0001')
        .min(7, 'VLDN0012')
        .max(20, 'VLDN0012')
        .matches(/\d/, 'VLDN0012')
        .matches(/[A-Za-z]/, 'VLDN0012')
        .matches(/^\S*$/, 'VLDN0020')
        .strict(true);
     const confirmPasswordSchema =
      Yup.string()
        .trim()
        .required('VLDN0003')
        .matches(/^\S*$/, 'VLDN0020')
        .when("password", (password, schema) => {
          return password
            ? schema.oneOf([Yup.ref("password")], 'VLDN0004')
            : schema;
        });
    


export const signUpValidationSchema =  Yup.object().shape({
    firstName: firstNameSchema,
    email: emailSchema,
    phone: phoneSchema,
    afid: afidSchema,
    password: passwordSchema,
    confirmPassword: confirmPasswordSchema
});


export const siginValidationSchema =  Yup.object().shape({
    email: emailSchema,
    password: passwordSchema,
});


export const otpValidateSchema = Yup.object().shape({
  otp: Yup.string().trim()
    .required('VLDN0022')
    .min(6, 'VLDN0023')
    .max(6, 'VLDN0023')
    .matches(/^[0-9\b]+$/, 'VLDN0024')
});
