import store from "../store";
import axios from "axios";
import { afLogoutReducer } from "../store/afAuth.slice";

export const afClient = axios.create({
    baseURL: '/v1/'
});

export const client = axios.create({
    baseURL:  process.env.REACT_APP_SHOP_URL + '/v1/'
});

afClient.interceptors.request.use(
    config => {
        const state = store.getState();
        const sessionId = state?.afAuth?.afUserData?.sessionId;
        if (!!sessionId) config.headers['Sessionid'] = sessionId;
        return config;
    },
    error => Promise.reject(error)
);

afClient.interceptors.response.use(
    res => res,
    error => {
        if (error.response && error.response.status === 401){
            store.dispatch(afLogoutReducer());
        }
        return Promise.reject(error)
    }
)

export const api = data => client(data)
    .then(res => res.data )
    .catch(err => { throw new Error(err?.response?.data?.errorMsg ?? err); });

export const afApi = data => afClient(data)
    .then(res => res.data )
    .catch(err => { throw new Error(err?.response?.data?.errorMsg ?? err); });

export const afApiWithSession = data => afClient(data)
    .then(res => ({ ...res.data, sessionId: res.headers.sessionid }))
    .catch(err => { throw new Error(err?.response?.data?.errorMsg ?? err); });

export const afLogin = data => afApiWithSession({
    method:'post',
    url:'login',
    data
});

export const afDashboard = url => afApi({
    method:'get',
    url
})

export const signUpAPI = data => afApi({
    method: 'post',
    url:'signup',
    data
});
export const verifyEmail =  data => afApi({
    method: 'post',
    url:`verifyemail`,
    data
});

export const languageGetApi = (ln,screen) => api({method:'get', url:`assets?language=${ln}&screen=${screen}`})
