import { configureStore } from "@reduxjs/toolkit";

import afAuth from "./afAuth.slice";
import language from "./language.slice";

const store = configureStore({
    reducer:{
        afAuth,
        language
    }
});

export default store