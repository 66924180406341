const convertToCSV = (data, headers) => {
    const headerRow = headers.map((header) => header.title);
    const csvContent = headerRow.join(',') + '\n';

    const csvRows = data.map((row) =>
        headers
            .map((header) => {
                const value = row[header.id];
                return typeof value === 'string' && value.includes(',')
                    ? `"${value}"`
                    : value;
            })
            .join(',')
    );

    return csvContent + csvRows.join('\n');
};
 
export const handleExportCSV = orderData => {
    const customHeaders = [
        { id: 'dataPlan', title: 'Name of the plan' },
        { id: 'createdTs', title: 'Purchased On' },
        { id: 'totalOrderAmount', title: 'Price' },
        { id: 'orderStatus', title: 'Order Status' },
    ];
    const csvData = convertToCSV(orderData, customHeaders);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'Sales.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}