import { createSlice } from "@reduxjs/toolkit";
import assets from '../assets/data/assets.json'
const removeNewFromKeys = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        const newKey = key.replace("-new", "");
        return [newKey, value];
      })
    );
  };
const languageSlice = createSlice({
    name:'language',
    initialState:{activelang:assets,en:removeNewFromKeys(assets)},
    reducers:{
        setLanguageIntial(state,actions){
            const{k,v} =actions.payload;
            state.activelang = {...state.activelang, ...removeNewFromKeys(v)};
            state[k] = {...state[k], ...v};
        },
        setLanguageStore(state,actions){
            state.activelang = state[actions.payload.k];
        }
    }
})

export const {setLanguageStore,setLanguageIntial} = languageSlice.actions;
export default languageSlice.reducer;