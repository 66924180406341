import React from "react";
import "./TermsAndCondition.scss";
import "primeflex/primeflex.css";
import Layout from "../../components/layout/Layout";
import data from "../../assets/data/cookiePolicy.json";
import SupportMailsSection from "../../components/SupportMailsSection";
import * as PR from "../../prime-modules/index";


const renderList = (list, className) => (
  <ul className={className}>
    {list.map((listItem, listIndex) => (
      <li key={listIndex} className="custom-li">
        <div className="flex">
          <span dangerouslySetInnerHTML={{ __html: listItem.text }} />
        </div>
      </li>
    ))}
  </ul>
);

const renderSubList = (subList) => (
  <ul className="sub-number-ol-two">
    {subList.map((listItem, listIndex) => (
      <li key={listIndex} className="custom-li">
        {listItem.listBold.length !== 0 && (
          <div className="flex">
            <p dangerouslySetInnerHTML={{ __html: listItem.listBold }} />
          </div>
        )}
        {listItem.list.length !== 0 && (
          <div className="flex">
            <p
              className="sublist-p"
              dangerouslySetInnerHTML={{ __html: listItem.list }}
            />
          </div>
        )}
        <span dangerouslySetInnerHTML={{ __html: listItem.content }} />
      </li>
    ))}
  </ul>
);

function CookiePolicy() {

  const providerBody = (rowData) => {

    return (
      <>
        <div>
          <p
            className="provider"
            dangerouslySetInnerHTML={{ __html: rowData.provider }}
          />
        </div>
      </>
    );
  };
  return (
    <Layout>
      <section className="about-blocks-section tc-block">
        <div className="terms-and-conditions-section">
          {/* <p className="page">{data.heading}</p> */}
          <h1>{data.heading}</h1>
          <p
            className="subHeading"
            dangerouslySetInnerHTML={{ __html: data.subHeading }}
          />
          <p dangerouslySetInnerHTML={{ __html: data.description }} />
          {data.sections?.map((section, sectionIndex) => (
  <div key={`section-${sectionIndex}`}>
    {Object.keys(section).map((key) => {
      const value = section[key];
      switch (key) {
        case "title":
          return (
            <div className="terms-section" key={`title-${sectionIndex}`}>
              <h2>{section.title.text}</h2>
            </div>
          );
        case "listTitle":
          return <p key={`listTitle-${sectionIndex}`}>{section.listTitle}</p>;
        case "list":
        case "list2":
        case "list3":
          return renderList(section[key], "sub-number-ol");
        case "subList":
          return renderSubList(section.subList);
        case "content":
        case "content2":
          return value.map((text, index) => (
            <p key={`content-${sectionIndex}-${index}`} dangerouslySetInnerHTML={{ __html: text }} />
          ));
        case "table":
          if (section.title.text === "What Cookies does Our Site use?") {
            return (
              <PR.DataTable value={section.table} className="tb-style" key={`table-${sectionIndex}`}>
                <PR.Column field="nameofCooke" header="Name of Cookie" />
                <PR.Column field="purposetype" header="Purpose & Type" />
                <PR.Column field="strictlynecessary" header="Strictly Necessary" />
              </PR.DataTable>
            );
          }
          if (section.title.text === "How long will you keep my personal data?") {
            return (
              <PR.DataTable value={section.table} className="tb-style" key={`table-${sectionIndex}`}>
                <PR.Column field="typeOfData" header="Type of data" />
                <PR.Column field="howLongWeKeepIt" header="How long we keep it" />
              </PR.DataTable>
            );
          }
          break;
        case "table2":
          if (section.title.text === "What Cookies does Our Site use?") {
            return (
              <PR.DataTable value={section.table2} className="tb-style" key={`table2-${sectionIndex}`}>
                <PR.Column field="nameofCooke" header="Name of Cookie" />
                <PR.Column field="purposetype" header="Purpose & Type" />
                <PR.Column field="provider" header="Provider" body={providerBody} />
                <PR.Column field="strictlynecessary" header="Strictly Necessary" />
              </PR.DataTable>
            );
          }
          break;
        default:
          return null;
      }
    })}
  </div>
))}
        </div>
      </section>
      {/* Support Mails Section */}
      <SupportMailsSection />
    </Layout>
  );
}

export default CookiePolicy;
