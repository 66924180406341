import React, { useState } from 'react';
import { twitter, linkedIn, facebook, instagram  } from "../../assets/images";
import * as PR from "../../prime-modules/index";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Footer = () => {
    const { labels } = useSelector(state => state.language?.activelang);
    const [year] = useState(new Date().getFullYear())
    
    return (
    <>
        <footer className="footer-section" style={{borderTop:'none', paddingTop:'0px'}}>
            {/* <div className='footer-info'>
                <div className='grid'>
                    <div className="col-12 md:col-6 lg:col-3 order-1">
                        <PR.Image src={footerLogo} alt="data2go logo"/>
                    </div>
                    <div className="col-12 md:col-6 lg:col-3 order-2">
                        <h3>{labels?.LBLN0176}</h3>
                        <p><a href={`tel:${globalConfig.callUsPhoneNumber}`} className="email-link">{globalConfig.callUsPhoneNumber}</a></p> 
                        <p><a href={`mailto:${globalConfig.supportEmail}`} className="email-link">{globalConfig.supportEmail}</a></p>
                        <p><a href={`mailto:${globalConfig.salesEmail}`} className="email-link">{globalConfig.salesEmail}</a></p>
                    </div>
                    <div className="col-12 md:col-6 lg:col-3 order-3">
                        <h3>{labels?.LBLN0177}</h3>
                        <p className='footer-button' onClick={navigate.bind(null,"/privacy-policy")}>{labels?.LBLN0075}</p>
                        <p className='footer-button' onClick={navigate.bind(null,"/terms-and-conditions")}>{labels?.LBLN0076}</p>
                        <p className='footer-button' onClick={navigate.bind(null,"/cookie-policy")}>{labels?.LBLN0128}</p>
                    </div>
                    <div className="col-12 md:col-6 lg:col-3 order-4">
                        <h3>{labels?.LBLN0179}</h3>
                        <div className='flex align-center gap-2 payment-accept'>
                            <PR.Image src={visa} alt="Visa"/>
                            <PR.Image src={masterCard} alt="Master Card"/>
                            <PR.Image src={applePay} alt="Apple Pay"/>
                            <PR.Image src={gPay} alt="Google Pay"/>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='copyrights-section'>
                <div className='flex align-center justify-content-between mobile-flex'>
                    <div className='copyrights-left'>
                        <p>&copy;{year} {labels?.LBLN0065} | {labels?.LBLN0064} <Link to="https://www.bluehouse-technology.co.uk/" target='_blank'>Bluehouse Technology.</Link></p>
                    </div>
                    <div className='social-icons flex align-center gap-4'>
                        <Link to="https://x.com/data2go_mobi" target='_blank'><PR.Image src={twitter} alt="Twitter"/></Link>
                        <Link to="https://uk.linkedin.com/company/bluehouse-technology" target='_blank'><PR.Image src={linkedIn} alt="LinkedIn"/></Link>
                        <Link to="https://www.facebook.com/data2go.mobi" target='_blank'><PR.Image src={facebook} alt="Facebook"/></Link>
                        <Link to="https://www.instagram.com/data2go.mobi" target='_blank'><PR.Image src={instagram} alt="Instagram"/></Link>
                    </div>
                </div>
            </div>
        </footer>
    </>
  );
};

export default Footer; 